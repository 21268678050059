import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { CompanyName } from './CompanyName';
import { WhatWeDo } from './WhatWeDo';
import { FloaterDemo } from './FloaterDemo';
import { Contact } from './Contact';
import { Footer } from './Footer';
// import { Wallet } from './Wallet';

// Use require instead of import, and order matters
require('@solana/wallet-adapter-react-ui/styles.css');
require('./index.css');

ReactDOM.render(
    <StrictMode>
        <CompanyName />
        <WhatWeDo />
        <br />
        <FloaterDemo src="https://floaterassetswapdemo.web.app/" />
        <br />
        <Contact />
        <Footer />
    </StrictMode>,
    document.getElementById('root')
);
 