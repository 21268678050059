import { FC } from "react";
import styles from "./css/main.module.css";

export const Footer: FC = () => {
    return (
        <div>
           <p className={styles.centered}>Create your lore with Lore Labs.</p>
        </div>
    );
};
