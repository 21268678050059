import React, { IframeHTMLAttributes } from "react";
import styles from "./css/floaterDemo.module.css";
import mainStyles from "./css/main.module.css";
import { FilePicker } from "./FilePicker";

type DemoProps = {
    src: string
}

export class FloaterDemo extends React.Component<DemoProps> {

    private frameTitle = "Floater Demo";
    private frameId = "floaterDemoFrame";

    private onAvatarSelect(dataUrl: string) {
        var iframeWindow = (document.getElementById(this.frameId) as any).contentWindow;
        if (iframeWindow) {
            iframeWindow.postMessage({src: dataUrl}, "*");
        }
    }

    render() {
        return (
            <div className={mainStyles.centered}>
                <div className={mainStyles.verticalMiddle}>
                <FilePicker buttonText="Select Avatar Image" applyImage={this.onAvatarSelect.bind(this)}/>
                </div>
               <iframe title={this.frameTitle} id={this.frameId} className={styles.demo} src={this.props.src} height="512" width="512" />
            </div>
        );
    }
}
