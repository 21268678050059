import { useFilePicker } from "use-file-picker";
import { FC } from "react";

type FilePickerProps = {
    buttonText: string,
    applyImage: (dataUrl: string) => void
}

export const FilePicker: FC<FilePickerProps> = (props) => {

    var dataUrl: string;
    var imageLoaded: boolean = false;

    const [openFileSelector, { filesContent, loading, errors }] = useFilePicker({
        readAs: 'DataURL',
        accept: 'image/*',
        multiple: false,
        limitFilesConfig: { max: 1 },
        // minFileSize: 0.1, // in megabytes
        maxFileSize: 10,
        imageSizeRestrictions: {
          maxHeight: 1024, // in pixels
          maxWidth: 1024,
          minHeight: 32,
          minWidth: 32,
        },
    });

    if (loading) {
        return <div>Loading...</div>;
    }

    if (errors.length) {
        return <div>Error...</div>;
    }

    if (filesContent.length) {
        dataUrl = filesContent[0].content;
        imageLoaded = true;
    }

    return (
        <div>
          <button onClick={() => openFileSelector()}>{props.buttonText} </button> <span/>
          <button onClick={() => props.applyImage(dataUrl)} hidden={!imageLoaded}>Apply Image</button>
          <br />
          {filesContent.map((file, index) => (
            <div key={index}>
              <p>{file.name}</p>
            </div>
          ))}
        </div>
      );
};
