import { FC } from "react";
import styles from "./css/whatWeDo.module.css";
import mainStyles from "./css/main.module.css";

export const WhatWeDo: FC = () => {
    return (
        <div className={mainStyles.centered}>
            <p className={styles.message}>
            Welcome to the Lab! We're hard at work standing up our NFT builder and as such you'll see things change.
            Feel free to try out what we have so far!
            </p>
        </div>
    );
};
