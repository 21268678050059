import classNames from "classnames";
import { FC } from "react";
import styles from "./css/main.module.css";

export const Contact: FC = () => {

    let cl = classNames('.centered', '.link');

    return (
        <>
        <div>
            <p className={styles.centered}>Feedback? Reach out to</p>
        </div>
        <div className={styles.centered}>
            <a className={styles.link} href="mailto: hello@lorelabs.app">hello@lorelabs.app</a>
        </div>
        </>
    );
};
