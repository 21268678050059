import { FC } from "react";
import styles from "./css/main.module.css";

export const CompanyName: FC = () => {
    return (
        <div>
            <h1 className={styles.centered}>Lore Labs</h1>
        </div>
    );
};
